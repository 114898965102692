const AddOrRemoveHours = [
    {
        value: 'ADD',
        label: 'Adicionar horas'
    },
    {
        value: 'REMOVE',
        label: 'Remover horas'
    },
];

export default AddOrRemoveHours;