import styled from "styled-components";

const HoursSumAndTablesArea = styled.div`

`;

const FlexDiv = styled.div`
  display: flex;
`;
export { HoursSumAndTablesArea, FlexDiv };
